





























































































import { getDashboardDataBySection } from '@/api/consoleApi/user'
import { Component, Vue } from 'vue-property-decorator'
import { UserModule } from '@/store/modules/user'
import VsSection from '../VsSection/Index.vue'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'

@Component({
    name: 'VsSubscriptions',
    components: {
        VsSection,
        VsDropdownButton,
    },
})
export default class extends Vue {
    private subscribers = {
        total: 0,
        global_suppression_list: 0,
    }

    private lists = {
        total: 0,
        open_rate_total: 0,
        click_rate_total: 0,
    }

    private test = {}

    get rules () {
        return UserModule.user.configuration.rules
    }

    get subscribersLimit () {
        return this.rules.subscribersLimit
    }

    get recipientLimit () {
        return this.rules.recipientLimit
    }

    async mounted () {
        await this.getSubscribers()
    }

    private async getSubscribers () {
        try {
            const resp = await getDashboardDataBySection('subscribers')
            this.subscribers = resp.data.subscribers
            this.lists = resp.data.lists
        } catch (e) {
            console.log(e)
        }
    }
}
