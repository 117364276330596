











































































import { Component, Vue } from 'vue-property-decorator'
import { getLists } from '@/api/consoleApi/recipients'
import VsLoader from '@/components/VsLoader/Index.vue'

@Component({
    name: 'VsSelectImportList',
    components: {
        VsLoader,
    },
})
export default class extends Vue {
    private show = false
    private loading = false
    private recipients: any[] = []
    private list = ''
    private importType: 'list' | 'suppression' = 'list'
    $refs: any

    async getLists () {
        this.loading = true
        try {
            const response = await getLists({ limit: 10000 })
            this.recipients = response.data.data.map((e: any) => {
                return {
                    value: e.id,
                    label: e.name,
                }
            })
            if (this.recipients.length === 0) {
                this.importType = 'suppression'
            }
        } catch (e) {
            this.recipients = []
            console.log(e)
        }
        this.loading = false
    }

    async submit () {
        const result = await this.$refs.selectListForm.validate()
        if (!result) {
            return
        }
        this.$router.push({
            name: this.importType === 'list' ? 'listImportSelectMethod' : 'listImportSelectMethodSuppression',
            params: this.importType === 'list' ? {
                listId: this.list,
            } : undefined,
        })
    }

    public async openModal () {
        this.list = ''
        this.importType = 'list'
        this.show = true
        await this.getLists()
    }

    private close () {
        this.show = false
    }
}
