








































































import { getDashboardDataBySection } from '@/api/consoleApi/user'
import VsChart from '@/components/VsChart/Index.vue'
import { Component, Vue, Watch } from 'vue-property-decorator'
import VsSection from '../VsSection/Index.vue'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'

@Component({
    name: 'VsCampaignOverview',
    components: {
        VsChart,
        VsSection,
        VsDropdownButton,
    },
})
export default class extends Vue {
    private campaigns: any = {
        total: 0,
        delta: 0,
    }

    private yAxisRoundTo = 10
    private yAxisNumberOfItem = 10

    private emails: any = null
    private sms: any = null
    private typeCampaign: any = 'email'
    private barChartOptions: any = {
        tooltip: {
            trigger: 'axis',
            formatter: (payload: any) => {
                return `<span class="vs-h200">${this.$options?.filters?.formatDate(payload[0].axisValue, 'MMM YYYY')}</span><br/>${payload[0].marker} ${payload[0].seriesName}:  <span class="vs-h200">${this.$options?.filters?.formatNumber(payload[0].value)}</span> <br/>${payload[1].marker} ${payload[1].seriesName}:  <span class="vs-h200">${this.$options?.filters?.formatNumber(payload[1].value)}</span> <br/>${payload[2].marker} ${payload[2].seriesName}:  <span class="vs-h200">${this.$options?.filters?.formatPercentage(payload[2].value)}</span> <br/>${payload[3].marker} ${payload[3].seriesName}: <span class="vs-h200">${this.$options?.filters?.formatPercentage(payload[3].value)}</span>`
            },
            axisPointer: {
                type: 'shadow',
            },
        },
        grid: {
            left: 15,
            right: 15,
            bottom: 0,
            top: 40,
            containLabel: true,
        },
        legend: {
            selectedMode: false,
            itemGap: 30,
            type: 'scroll',
            data: [
                {
                    name: this.$t('general.campaigns'),
                },
                {
                    name: this.$t('general.automation'),
                },
                {
                    name: this.$t('general.openRate'),

                },
                {
                    name: this.$t('general.clickRate'),
                },
            ],
        },
        xAxis: {
            type: 'category',
            axisTick: {
                show: false,
            },
            axisLabel: {
                rotate: 25,
                margin: 15,
                formatter: (value: string) => {
                    return this.$options?.filters?.formatDate(value, 'MMM YYYY')
                },
            },
            nameTextStyle: {
                color: '#60686A',
            },
            data: [],
        },
        yAxis: [
            {
                type: 'value',
                position: 'left',
                min: 0,
                max: this.yAxisRoundTo,
                interval: this.yAxisRoundTo / this.yAxisNumberOfItem,
                nameTextStyle: {
                    color: '#60686A',
                },
                axisLabel: {
                    formatter: (value: number) => {
                        return this.$options?.filters?.formatNumber(value)
                    },
                },
            },
            {
                type: 'value',
                position: 'right',
                splitLine: {
                    show: false,
                },
                min: 0,
                max: 100,
                interval: 100 / this.yAxisNumberOfItem,
                axisLabel: {
                    formatter: (value: number) => {
                        return this.$options?.filters?.formatPercentage(value)
                    },
                },
                nameTextStyle: {
                    color: '#60686A',
                },
            },
        ],
        series: [
            {
                name: this.$t('general.campaigns'),
                data: [],
                type: 'bar',
                showBackground: false,
                stack: 'one',
                itemStyle: {
                    color: '#DEE8EF',
                },
            },
            {
                name: this.$t('general.automation'),
                data: [],
                type: 'bar',
                showBackground: false,
                stack: 'one',
                itemStyle: {
                    color: '#C5CED6',
                },
            },
            {
                name: this.$t('general.openRate'),
                data: [],
                type: 'line',
                yAxisIndex: 1,
                showBackground: false,
                areaStyle: {
                    color: 'rgba(208, 52, 73, 0.3)',
                },
                itemStyle: {
                    borderWidth: 1,
                    borderColor: 'rgba(208, 52, 73)',
                    color: 'rgba(208, 52, 73)',
                },
                lineStyle: {
                    color: 'rgba(208, 52, 73)',
                },
                backgroundStyle: {
                    color: 'rgba(208, 52, 73, 0.2)',
                },
            },
            {
                name: this.$t('general.clickRate'),
                data: [],
                yAxisIndex: 1,
                type: 'line',
                showBackground: false,
                areaStyle: {
                    color: 'rgba(64, 158, 220, 0.3)',
                },
                itemStyle: {
                    borderWidth: 1,
                    borderColor: 'rgba(64, 158, 220)',
                    color: 'rgba(64, 158, 220)',
                },
                lineStyle: {
                    color: 'rgba(64, 158, 220)',
                },
                backgroundStyle: {
                    color: 'rgba(64, 158, 220, 0.2)',
                },
            },
        ],
    }

    private options: any = [
        {
            value: 'email',
            label: 'Email',
            disabled: false,
            childs: [],
        },
        {
            value: 'sms',
            label: 'Sms',
            disabled: false,
            childs: [],
        },
    ]

    get typeStatistic () {
        if (this.typeCampaign === 'email') return this.emails
        if (this.typeCampaign === 'sms') return this.sms
    }

    async mounted () {
        await this.getCampaignsOverview()
    }

    private async getCampaignsOverview () {
        try {
            const resp = await getDashboardDataBySection('campaigns-overview')
            this.campaigns = resp.data.campaigns
            this.emails = resp.data.emails
            this.sms = resp.data.sms
            this.calcStatistic()
        } catch (e) {
            console.log(e)
        }
    }

    @Watch('typeCampaign')
    private setStatistic () {
        this.calcStatistic()
    }

    private calcStatistic () {
        const data = this.typeStatistic
        if (!data) return

        this.barChartOptions.xAxis.data = []
        this.barChartOptions.series[0].data = []
        this.barChartOptions.series[1].data = []
        this.barChartOptions.series[2].data = []
        this.barChartOptions.series[3].data = []

        data.messages.forEach((type: any) => {
            this.barChartOptions.xAxis.data.push(type.date)
            this.barChartOptions.series[0].data.push(type.sent_campaign)
            this.barChartOptions.series[1].data.push(type.sent_automation)
            this.barChartOptions.series[2].data.push(type.open.rate)
            this.barChartOptions.series[3].data.push(type.click.rate)
        })

        const max = this.calcRoundMaxToNextYaxisRound(data)
        this.barChartOptions.yAxis[0].max = max
        this.barChartOptions.yAxis[0].interval = max / this.yAxisNumberOfItem
    }

    private calcRoundMaxToNextYaxisRound (typeStatistic: any) {
        const moduleBy10 = this.calcMaxMessages(typeStatistic) % this.yAxisRoundTo
        let max = this.calcMaxMessages(typeStatistic)
        if (moduleBy10) {
            max = max - moduleBy10 + this.yAxisRoundTo
        }
        return max
    }

    private calcMaxMessages (typeStatistic: any) {
        if (!typeStatistic) return this.yAxisRoundTo
        const max = typeStatistic.messages.reduce((a: any, b: any) => {
            const totalA = a.sent_campaign + a.sent_automation
            const totalB = b.sent_campaign + b.sent_automation
            return totalA > totalB ? a : b
        })
        const maxCalc = max.sent_campaign + max.sent_automation
        return maxCalc < this.yAxisRoundTo ? this.yAxisRoundTo : maxCalc
    }
}
