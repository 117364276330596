















































































import { getFormsTotals } from '@/api/leadplus/forms'
import { Component, Vue } from 'vue-property-decorator'
import { UserModule } from '@/store/modules/user'
import VsSection from '../VsSection/Index.vue'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'

@Component({
    name: 'VsModulesAndPopUp',
    components: {
        VsSection,
        VsDropdownButton,
    },
})
export default class extends Vue {
    totalStatistics: any = {
        total: 0,
        totalActive: 0,
        totalErrors: 0,
        totalVisitors: 0,
        totalSubscribers: 0,
    }

    private lists: any = {}

    get rules () {
        return UserModule.user.configuration.rules
    }

    get formsLimit () {
        return this.rules.leadplusForms
    }

    get conversionRate () {
        if (this.totalStatistics.totalVisitors === 0) return 0
        if (this.totalStatistics.totalSubscribers === 0) return 0
        return (this.totalStatistics.totalSubscribers * 100) / this.totalStatistics.totalVisitors
    }

    async mounted () {
        await this.getFormsTotals()
    }

    private async getFormsTotals () {
        try {
            const { data } = await getFormsTotals()
            this.totalStatistics = data
        } catch (e) {
            console.log(e)
        }
    }
}
