









































































import {
    getCampaigns,
} from '@/api/consoleApi/campaigns'
import { Component, Vue } from 'vue-property-decorator'
import VsCampaignCard from '@/modules/campaigns/components/VsCampaignCard/Index.vue'
import VsCampaignExpandedCard from '@/modules/campaigns/components/VsCampaignExpandedCard/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'
import { ICampaignFromGetAll } from '@/api/consoleApi/dto/campaigns.dto'
import VsPreviewModal from '@/modules/campaigns/components/VsPreviewModal/Index.vue'

@Component({
    name: 'VsCampaignActivity',
    components: {
        VsCampaignCard,
        VsCampaignExpandedCard,
        VsLoader,
        VsDropdownButton,
        VsPreviewModal,
    },
})
export default class extends Vue {
    private campaigns: any = []
    private loading = true

    $refs!: {
        previewModal: VsPreviewModal
    }

    async beforeMount () {
        await this.getCampaigns()
    }

    private async getCampaigns () {
        this.loading = true
        try {
            const resp = await getCampaigns({
                orderBy: 'created_at',
                sortedBy: 'desc',
                page: 1,
                limit: 5,
                include: 'email,sms,label_tags',
                search: 'status:Failed,Sent,Ready,Pending Approval,Sending,Paused',
                searchFields: 'status:in',
            })
            this.campaigns = resp.data.data
        } catch (e) {
            this.campaigns = []
            console.log(e)
        }

        this.loading = false
    }

    private openPreviewModal (campaign: ICampaignFromGetAll) {
        this.$refs.previewModal.openModalNew(campaign[campaign.content_type]?.data, campaign.content_type)
    }
}
