







































import VsChart from '@/components/VsChart/Index.vue'
import { Component, Vue } from 'vue-property-decorator'
import { getStatistics } from '@/api/landingpage/statistics'
import VsSection from '../VsSection/Index.vue'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'

@Component({
    name: 'VsPages',
    components: {
        VsChart,
        VsSection,
        VsDropdownButton,
    },
})
export default class extends Vue {
    private pages: any = {
        pagesLimit: 0,
        totalPagesPublished: 0,
    }

    private barChartOptions: any = {
        tooltip: {
            trigger: 'axis',
            formatter: (payload: any) => {
                return `<span class="vs-h200">${this.$options?.filters?.formatDate(payload[0].axisValue, 'MMM YYYY')}</span><br/>${payload[0].marker} ${payload[0].seriesName}:  <span class="vs-h200">${this.$options?.filters?.formatNumber(payload[0].value)}</span>`
            },
            axisPointer: {
                type: 'shadow',
            },
        },
        grid: {
            left: 15,
            right: 15,
            bottom: 0,
            top: 40,
            containLabel: true,
        },
        legend: {
            itemGap: 30,
            selectedMode: false,
            data: [
                this.$t('dashboard.general.pageView'),
            ],
        },
        xAxis: {
            type: 'category',
            axisTick: {
                show: false,
            },
            axisLabel: {
                rotate: 25,
                margin: 15,
                formatter: (value: string) => {
                    return this.$options?.filters?.formatDate(value, 'MMM YYYY')
                },
            },

            nameTextStyle: {
                color: '#60686A',
            },
            data: [],
        },
        yAxis: {
            type: 'value',
            nameTextStyle: {
                color: '#60686A',
            },
            axisLabel: {
                formatter: (value: number) => {
                    return this.$options?.filters?.formatNumber(value)
                },
            },
        },
        series: [
            {
                name: this.$t('dashboard.general.pageView'),
                data: [],
                type: 'bar',
                showBackground: false,
                stack: 'one',
                itemStyle: {
                    color: '#DA5D6E',
                },
            },
        ],
    }

    private options: any = [
        {
            value: 'email',
            label: 'Email',
            disabled: false,
            childs: [],
        },
        {
            value: 'sms',
            label: 'Sms',
            disabled: false,
            childs: [],
        },
    ]

    async mounted () {
        await this.getLandingpageStatistics()
    }

    private async getLandingpageStatistics () {
        try {
            const { data } = await getStatistics('overview')
            this.pages = data
            this.calcStatistic()
        } catch (e) {
            console.log(e)
        }
    }

    private calcStatistic () {
        this.pages.pageView.forEach((page: any) => {
            this.barChartOptions.xAxis.data.push(page._id)
            this.barChartOptions.series[0].data.push(page.total)
        })
    }
}
