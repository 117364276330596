





























































































































































































import { AppModule } from '@/store/modules/app'
import { get } from 'lodash'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { CampaignStatusEnum, ICampaignFromGetAll } from '@/api/consoleApi/dto/campaigns.dto'
import { formatUnixDate } from '@/utils/formatter'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'

@Component({
    name: 'VsCampaignExpandedCard',
    components: {
        VsDropdownButton,
    },
})
export default class extends Vue {
    @Prop({
        default: null,
        required: true,
    }) private campaign!: ICampaignFromGetAll

    @Prop({
        default: null,
        required: false,
    }) private value!: string[]

    @Prop({
        default: false,
        required: false,
        type: Boolean,
    }) private hideActions!: boolean

    get isEmail () {
        return (this.campaign.content_type === 'email')
    }

    get isSms () {
        return (this.campaign.content_type === 'sms')
    }

    get isAbSplit () {
        return this.campaign.content_type === 'split_test'
    }

    get message () {
        if (this.campaign.email) return this.campaign.email.data
        if (this.campaign.sms) return this.campaign.sms.data
        return false
    }

    get sender () {
        return this.campaign.content_type === 'email' ? `${this.campaign.email?.data.from_name} (${this.campaign.email?.data.from_email})` : this.campaign.sms?.data.from
    }

    get messageId () {
        if (this.campaign.email && this.campaign.email.data.id) {
            return this.campaign.email.data.id
        }
        if (this.campaign.sms && this.campaign.sms.data.id) {
            return this.campaign.sms.data.id
        }
    }

    get sentOrScheduledTime () {
        if (!this.campaign?.status) return ''
        let date: any = ''
        switch (this.campaign.status) {
            case 'Paused':
            case 'Sending':
            case 'Failed':
            case 'Sent':
                date = this.campaign.sent_start
                break
            case 'Ready':
                if (this.campaign.schedule_type === 'Immediate') {
                    return this.$t('campaigns.campaignCard.scheduleTypes.immediate')
                } else if (this.campaign.schedule_type === 'Recursive') {
                    return this.$t('campaigns.campaignCard.scheduleTypes.ricorsive')
                } else {
                    if (this.campaign.scheduled) {
                        date = formatUnixDate(this.campaign.scheduled, 'DD MMMM YYYY HH:mm')
                    }
                }
                break
            case 'Pending Approval':
            case 'Draft':
            default: return date
        }
        return this.$options?.filters?.formatDate(date)
    }

    get is4Dem () {
        return AppModule.consoleConf?.is4Dem
    }

    get universityUrl () {
        return get(AppModule, 'universityUrl', '')
    }

    get badgeStatusAspect () {
        if (!this.campaign?.status) return ''
        switch (this.campaign?.status || null) {
            case CampaignStatusEnum.Ready:
                return 'info'
            case CampaignStatusEnum.Sending:
                return 'primary'
            case CampaignStatusEnum.Sent:
                return 'success'
            case CampaignStatusEnum.Failed:
                return 'alert'
            case CampaignStatusEnum.Paused:
                return 'warning'
            case CampaignStatusEnum['Pending Approval']:
            case CampaignStatusEnum.Draft:
            default:
                return 'grey'
        }
    }

    get badgeStatusIcon () {
        if (!this.campaign?.status) return ''
        switch (this.campaign?.status || null) {
            case CampaignStatusEnum.Ready:
                return 'clock'
            case CampaignStatusEnum.Sending:
                return 'outgoing'
            case CampaignStatusEnum.Paused:
                return 'pause'
            case CampaignStatusEnum['Pending Approval']:
                return 'clock'
            case CampaignStatusEnum.Sent:
                return 'double-check'
            case CampaignStatusEnum.Failed:
                return 'error'
            case CampaignStatusEnum.Draft:
                return 'edit'
        }
    }

    private redirectToUniversity () {
        window.open(`${this.universityUrl}/article/337-la-mia-campagna-e-stata-messa-in-pausa`, '_blank')
    }
}
