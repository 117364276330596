










































import { Component, Vue } from 'vue-property-decorator'
import VsSectionTitle from '../VsSectionTitle/Index.vue'

@Component({
    name: 'VsNews',
    components: {
        VsSectionTitle,
    },
})
export default class extends Vue {
}
