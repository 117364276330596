






import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
    name: 'VsSection',
    components: {
    },
})
export default class extends Vue {
    @Prop({ default: '', required: false }) title?: string
}
