





























































import { getDashboardDataBySection } from '@/api/consoleApi/user'
import { Component, Vue } from 'vue-property-decorator'
import { UserModule } from '@/store/modules/user'
import VsSection from '../VsSection/Index.vue'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'

@Component({
    name: 'VsAutomations',
    components: {
        VsSection,
        VsDropdownButton,
    },
})
export default class extends Vue {
    private automations = {
        total: 0,
        recovery: 0,
        total_email_sent: 0,
        total_sms_sent: 0,
        total_active: 0,
    }

    get rules () {
        return UserModule.user.configuration.rules
    }

    get automationLimit () {
        return this.rules.automation
    }

    async mounted () {
        await this.getDashboardAutmation()
    }

    private async getDashboardAutmation () {
        try {
            const resp = await getDashboardDataBySection('automation')
            this.automations = resp.data
        } catch (e) {
            console.log(e)
        }
    }
}
