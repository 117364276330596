



















import { AppModule } from '@/store/modules/app'
import { Component, Vue } from 'vue-property-decorator'

@Component({
    name: 'VsTermsAndCondictions',
})
export default class extends Vue {
    get siteUrl () {
        return AppModule.siteUrl
    }
}
